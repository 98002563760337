import axios from 'utils/axios';

import { formatAsLocalizedDateStringIgnoringTZ, formatDateUTCWithoutTime } from 'utils/date';
import { omitBy, isNull, cloneDeep } from 'lodash';

export async function addMedication(patientId, medicationData) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/new`, medicationData);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function addHistoricalFillEntry(patientId, medicationId, { name, strength, fillDate, pickupDate, daysSupply }) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical`,
            { name, strength, fillDate, pickupDate, daysSupply }
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function updateHistoricalFillEntry(
    patientId,
    medicationId,
    historicalId,
    { name, strength, fillDate, pickupDate, daysSupply }
) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical/${historicalId}`,
            { name, strength, fillDate, pickupDate, daysSupply }
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function deleteHistoricalFillEntry(patientId, medicationId, historicalId) {
    try {
        const response = await axios.delete(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical/${historicalId}`
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function updatePharmacyInfo(patientId, medicationId, { pharmacyName, pharmacyPhone }) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/pharmacy-info`,
            { pharmacyName, pharmacyPhone }
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getMedicationHistorical(patientId, medicationId) {
    try {
        const response = await axios.get(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/historical`
        );
        for (let i = 0; i < response.data.historical.length; i += 1) {
            const row = response.data.historical[i];
            row.fillDate = formatAsLocalizedDateStringIgnoringTZ(row.fillDate);
            row.pickupDate = formatAsLocalizedDateStringIgnoringTZ(row.pickupDate);
        }
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getMedicationList(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function getMedication(patientId, medicationId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}

export async function submitRefillWorkflow(patientId, medicationId, refillWorkflow) {
    try {
        // only include actual values to match schema of the different states for this api
        const body = cloneDeep(omitBy(refillWorkflow, isNull));
        if (body.fillDate) {
            body.fillDate = formatDateUTCWithoutTime(body.fillDate);
        }
        if (body.pickupDate) {
            body.pickupDate = formatDateUTCWithoutTime(body.pickupDate);
        }

        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/refill-workflow`,
            body
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function updateMedicationStatus(patientId, medicationId, statusData) {
    try {
        const response = await axios.post(
            `/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/${medicationId}/status`,
            statusData
        );
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getPatientMedicationMetrics(patientId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/clinic-id/{clinicId}/patient/${patientId}/medication/metrics`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
